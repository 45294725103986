import { scriptLoader } from "./script-loader"

const siteKey = '6LdRfRwqAAAAAGzDSKG4bFEqR-EbI_BzR3J0Gttr';
declare const grecaptcha: any;

class CaptchaService {
    init():Promise<any> {
        return scriptLoader.loadScript('https://www.google.com/recaptcha/enterprise.js?render=' + siteKey, { oneTrustIgnore: true });
    }

    getToken():Promise<string> {
        if(!window['grecaptcha']) throw 'Recaptcha is not initialized';

        return new Promise((resolve, reject) => {            
            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(siteKey)
                    .then(resolve)
                    .catch(reject);
            });
        })
    }
}

export const captchaService = new CaptchaService();